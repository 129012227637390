.container {
    width: min(100% - 2rem, 50rem);
    margin-inline: auto;
}


.content {
    --gap: clamp(1rem, 6vw, 3rem);
    --full: minmax(var(--gap), 1fr);
    --content: min(50ch, 100% - var(--gap) * 2);
    --popout: minmax(0, 2rem);
    --feature: minmax(0, 5rem);

    display: grid;
    grid-template-columns:
    [full-start] var(--full)
    [feature-start] var(--feature)
    [popout-start] var(--popout)
    [content-start] var(--content) [content-end]
    var(--popout) [popout-end]
    var(--feature) [feature-end]
    var(--full) [full-end];
}

.content > * {
    grid-column: content;
}

.popout {
    grid-column: popout;
}

.feature {
    grid-column: feature;
}

.full {
    grid-column: full;
}

.center-grid {
    display: grid;
    place-items: center;
}

.center-grid-2 {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 4fr;
    align-items: center;
    justify-content: space-around;
    gap: var(--size-6);
    margin-block-end: var(--size-10);
}

.content {
    margin-block: var(--gap);
}
