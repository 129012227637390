
*,
*::before,
*::after {
    box-sizing: border-box;
}

header {
    position: fixed;
    top: 0;
    width: 100%;

    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    justify-content: space-around;
    gap: var(--size-1);

    padding-inline: var(--size-4);
    padding-block: var(--size-4);
    background-color: var(--surface-2);

    div.header-logo {
        img {
            height: var(--font-size-7);
        }
    }

    div.header-title {
        font-family: var(--font-sans-thin);
        font-size: var(--font-size-3);

        h1 {
            text-align: center;
            max-inline-size: unset;
        }
    }

    div.header-links {
        text-align: right;

        i {
            color: var(--mexican-pink);
            /*display: inline-block;*/
            font-size: var(--font-size-7);
        }
    }
}

header.sticky {
    transition: padding-block 500ms ease-in-out;

    padding-block: var(--size-1);
    border-bottom: 1px solid var(--mexican-pink);

    div.header-logo {
        img {
            height: var(--font-size-5);
        }
    }

    div.header-title {
        font-family: var(--font-sans-black);

        h1 {
            font-size: var(--font-size-4);
        }
    }

    div.header-links {
        i {
            font-size: var(--font-size-5);
        }
    }
}

body {
    margin: 0;
    line-height: var(--font-lineheight-4);
}

a {
    color: var(--link);

    &:hover {
        color: var(--link-hover);
    }

    &:visited {
        color: var(--link-visited);
    }
}

h1 {
    color: var(--brand);
}

code {
    font-family: var(--font-code);
}

section.content {
    margin-top: 200px;
}

.container {
    width: min(100% - 2rem, 50rem);
    margin-inline: auto;
}

.logo-centered {
    display:block;
    margin-block: auto;
    width: 100%;
}
